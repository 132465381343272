<template>
  <div>
    <div class="recorder-wrap">
      <div :class="recording ? 'recorder recording' : 'recorder'">
        <button type="button" @click="record" :disabled="!audioAuth">
          <i class="el-icon-microphone" v-show="!recording"></i>
          <span v-show="recording">
            <img :src="require('@/assets/icon/icon_parse.svg')" alt />
            <b>{{ timeView }}</b>
          </span>
        </button>
      </div>
      <el-button
        style="margin-left: 20px"
        type="primary"
        @click="record"
        v-if="isReply && !recording && !!audioValue"
        >重新录制</el-button
      >
    </div>
    <AudioError :onChange="handleAudioError" />
  </div>
</template>
<script>
import Recorder, { ENCODE_TYPE } from 'recorderx'
import AudioError from '@/components/ExercisesContent/components/audio-error'
// encodeWAV
export default {
  components: {
    AudioError
  },
  props: {
    isReply: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rc: 0,
      recording: false,
      time: 0,
      timeView: '00:00',
      timeCount: null,
      audioValue: null,

      audioAuth: false
    }
  },
  methods: {
    handleAudioError(audioError) {
      this.audioAuth = !audioError
    },
    record() {
      this.$emit('record')
      if (this.recording) {
        this.pauseRecorder()
      } else {
        this.startRecorder()
      }
    },
    // 开始录音
    startRecorder() {
      this.rc = new Recorder()
      this.start()

      this.$nextTick(() => {
        this.rc
          .start()
          .then(() => {
            console.log('start recording')
          })
          .catch((error) => {
            console.log('Recording failed.', error)
          })
        this.recording = true
      })
    },
    // 暂停音频
    pauseRecorder() {
      if (this.time <= 4) {
        this.$layer.msg('录音时间不能小于4秒')
        this.stop()
        this.recording = false
        return
      }
      this.rc.pause()
      this.recording = false
      // //  重置父组件音频播放start
      // this.audioValue = null;
      // this.sendMsg();
      // //  重置父组件音频播放end
      // setTimeout(() => {
      this.getRecorder()
      this.stop()
      // }, 100);
    },
    // 获得音频
    getRecorder() {
      var wav = this.rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV
        // compressible: true
      })
      this.audioValue = wav
      this.sendMsg()
      // });
    },
    sendMsg() {
      //func: 是父组件指定的传数据绑定的函数，this.msg:子组件给父组件传递的数据
      this.$emit('func', this.audioValue)
    },
    // 计时
    timer() {
      if (this.time >= 40) {
        this.pauseRecorder()
        return
      }
      this.time += 1
      let min, sec
      min = sec = 0
      min = parseInt(this.time / 60)
      sec = parseInt(this.time % 60)
      if (sec < 10) {
        sec = '0' + sec
      }
      if (min < 10) {
        min = '0' + min
      }
      this.timeView = min + ':' + sec
    },
    start() {
      //开始计时
      this.timeCount = setInterval(this.timer, 1000)
    },

    stop() {
      //停止计时
      clearInterval(this.timeCount)
      this.time = 0
      this.timeView = '00:00'
    }
  }
}
</script>
<style scoped lang="less">
.recorder-wrap {
  display: flex;
  align-items: center;
}
.recorder {
  box-sizing: border-box;
  width: 66px;
  height: 66px;
  margin: 0 auto;
  border: 2px solid rgba(241, 84, 100, 0.2);
  border-radius: 50%;
  padding: 3px;
  background-color: white;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;

  button {
    white-space: nowrap;
    border-radius: 50%;
    border: none;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      150deg,
      rgba(240, 80, 101, 1) 0%,
      rgba(253, 167, 92, 1) 100%
    );
    color: white;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
.recorder.recording {
  button {
    font-size: 0;
  }
  span {
    display: block;
    text-align: center;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
    }
    b {
      display: block;
      font-size: 12px;
      margin-top: 2px;
      line-height: 1;
      font-weight: normal;
    }
  }
}
</style>
