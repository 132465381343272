<template>
  <div class="audio-error" v-if="show">
    <div>
      <i class="el-icon-error"></i>
      <p>{{ i18n.Pra.text15 }}</p>
    </div>
    <div class="audio-error-btn" @click="open">{{ i18n.Pra.text16 }}</div>
    <AudioOpen :visible="showDialog" @closeDialog="handleDialog" />
  </div>
</template>

<script>
import AudioOpen from '@/components/ExercisesContent/components/audio-open.vue'
import i18n from '@/i18n/i18n'

export default {
  components: {
    AudioOpen,
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showDialog: false,
      show: false,
    }
  },
  created() {
    if (!navigator.mediaDevices.getUserMedia) {
      this.$layer.msg(
        `"<div class='error-icon'></div><div>${
          i18n.locale === 'ch'
            ? '当前不支持录音'
            : 'Recording is not currently supported'
        }</div>"`
      )
      return
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((...argus) => this.hideInfo(argus))
      .catch((...argus) => this.showInfo(argus))
  },
  methods: {
    hideInfo() {
      this.show = false
      this.onChange(this.show)
    },
    showInfo() {
      this.show = true
      this.onChange(this.show)
    },
    open() {
      this.showDialog = true
    },
    handleDialog() {
      this.showDialog = !this.showDialog
    },
  },
}
</script>

<style lang="less">
.audio-error {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 0;
  width: 90%;
  max-width: 778px;
  min-width: 400px;
  height: 35px;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 5px;
  color: #ff1a2e;
  font-size: 14px;
  p {
    display: inline-block;
    margin-left: 5px;
  }
  i {
    font-size: 18px;
  }
  .audio-error-btn {
    cursor: pointer;
  }
}
</style>
